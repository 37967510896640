import { useQuery } from "@tanstack/react-query";
import { playFetch } from "./playfetch";

export const useGetQuery = (
                url,
                cacheTime = 30 * 60 * 1000) => {
    return useQuery({
                queryKey: url.split("/"),
                queryFn: () => playFetch(url),
                gcTime: cacheTime,
                staleTime: cacheTime,
                refetchOnWindowFocus: false
            })
};
