import React from "react";
import PriceAdjust from "./PriceAdjust";
import GlobalOfferings from "./GlobalOfferings";
import ManageMBLThreshold from "./ManageMBLThreshold";
import QuickNav from "./QuickNav";
import Jobs from "./Jobs";
import ApiCfg from "./ApiCfg";
import { recordsCfg } from "./config";

const Records = ({props}) => {
    return (
        <>
        {(props.id === recordsCfg.TYPE.PRICEADJUST) && <PriceAdjust {...props} />}
        {(props.id === recordsCfg.TYPE.GLOBALOFFERINGS) && <GlobalOfferings {...props} />}
        {(props.id === recordsCfg.TYPE.MBLTHRESHOLD) && <ManageMBLThreshold {...props} />}
        {(props.id === recordsCfg.TYPE.JOBS) && <Jobs {...props} />}
        {((props.id === recordsCfg.TYPE.QUICKLINKS) ||
            (props.id === recordsCfg.TYPE.FEATUREDRACE) ||
            (props.id === recordsCfg.TYPE.FEATUREDMATCH)) && <QuickNav {...props} />}
        {(props.id.split("/")[0] === recordsCfg.TYPE.APICFG) && <ApiCfg {...props} />}
        </>
    );
};
export default Records;