import React from "react";
import Display from "../Common/display";
import { apiCfg } from "./config";
import { fetch } from "../Common/lib/fetch";
import Operation from "./operation";
import { commonCfg } from "../Common/config";

const ApiCfg = ({...props}) => {
    const [cfgName, setCfgName] = React.useState(props.id.split("/")[1]);

    React.useEffect(() => {
        setCfgName(props.id.split("/")[1])
    }, [props.id]);

    const fetchData = async (envName) => {
        const requestData = envName.map((env) => {
            return {"envName": env, "apiUrl": `${props.apiUrl}/fetch/${cfgName}`}
        })

        const data = await fetch(
                                apiCfg[cfgName].KEYS.map(key => key.name),
                                requestData,
                                false);
        return data;
    }

    const renderOperation = (refetch, record, readOnly, operation) => {
        return <Operation
                    {...props}
                    keys={apiCfg[cfgName].KEYS}
                    actionWidth={apiCfg[cfgName].ACTIONWIDTH}
                    cfgName={cfgName}
                    refetchData={refetch}
                    readOnly={readOnly}
                    operation={operation}
                    record={record}
                />
    }

    const renderCreate = (refetch) => {
        return renderOperation(refetch, undefined, false, commonCfg.OPERATIONS.CREATE)
    }

    const renderDelete = (refetch, record) => {
        return renderOperation(refetch, record, true, commonCfg.OPERATIONS.DELETE)
    }

    const renderUpdate = (refetch, record) => {
        return renderOperation(refetch, record, false, commonCfg.OPERATIONS.UPDATE)
    }

    return (
        <Display
            {...props}
            colWidth={apiCfg[cfgName].KEYS.map(key => key.width)}
            actionWidth={apiCfg[cfgName].ACTIONWIDTH}
            keys={apiCfg[cfgName].KEYS.map(key => key.name)}
            operations={apiCfg[cfgName].OPERATIONS}
            fetchData={(envName) => fetchData(envName)}
            displayActionRecord={false}
            renderCreate={(envName, refetch) => renderCreate(refetch)}
            renderUpdate={(envName, refetch, records) => renderUpdate(refetch, records)}
            renderDelete={(envName, refetch, records) => renderDelete(refetch, records)}
        />
    );
};
export default ApiCfg;