import React, { useState, useEffect } from "react";
import keys from 'lodash/keys';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import SimplePicker from "./simplepicker";
import CascadePicker from "./cascadepicker";
import TabPicker from "./tabpicker";
import { getEnvironments, getProjectId } from "../../Authentication";
import { envType } from "../config";
import { mapEnvPickerTypeToGitopsGroup } from "../../../lib/gitops";

const EnvPicker = ({...props}) => {
    const [simpleData, setSimpleData] = useState([])
    const [cascadeData, setCascadeData] = useState([])
    const [envClass, setEnvClass] = useState(undefined)
    const [forceNoCascade, setForceNoCascade] = useState(false)

    useEffect(() => {
        async function fetchAuthEnvironments() {
            const environments = await getEnvironments()
            let envData = props.type.map((envItem, index) => {
                let envMap = environments.core;
                let filter = 'ALL'
            
                if (envItem.split('/').shift() === envType.WINTER) {
                    envMap = environments.winter;
                    setEnvClass(envType.WINTER)
                } else if (envItem.split('/').shift() === envType.SREPLAYBOOK) {
                    envMap = environments.sreplaybook;
                    setEnvClass(envType.SREPLAYBOOK)
                } else if (envItem === envType.KAFKA) {
                    envMap = environments.kafka;
                    setEnvClass(envType.KAFKA)
                } else if (envItem.split('/').shift() === envType.WINCORE) {
                    envMap = environments.wincore;
                    if ( props.type.length === 1 ) {
                        setEnvClass(envType.WINCORE)
                    } else {
                        /* As wincore is type of core env only. to make the display logic simple, just pass class as CORE */
                        setEnvClass(envType.CORE)
                    }
                } else if (envItem.split('/').shift() === envType.CONSOLIDATED) {
                    envMap = environments.consolidated;
                    setEnvClass(envType.CONSOLIDATED)
                } else {
                    setEnvClass(envType.CORE)
                }

                filter = envItem.split('/').pop().toUpperCase()
            
                let data = envMap?.map((item, index) => {
                    if ((props.exclude === undefined) || (props.exclude.includes(item.label) === false)) {
                        if ((envItem.split('/').shift() === envType.CORE) && 
                            ((filter === 'ALL') || (filter === item.type))) {
                            let value = item.label.toLowerCase() + '-betcloud-' + getProjectId()
                            if (props.isGitopsStyle === true) {
                                value = item.label.toLowerCase()
                            }
                            return ({
                                label: item.label,
                                value: value,
                                type: item.type,
                                gitops_group: item.gitops_group
                            });
                        } else if ((envItem.split('/').shift() === envType.WINCORE) &&
                                    ((filter === 'ALL') || (filter === item.type))) {
                            let value = item.label + '-winter-' + getProjectId()
                            if (props.isGitopsStyle === true) {
                                value = item.label.toLowerCase() + '-' + envType.WINCORE
                            }
                            if (props.type.length === 1) {
                                return ({
                                    label: item.label + '-' + envType.WINCORE,
                                    value: value
                                });
                            } else {
                                return ({
                                    label: item.label + '-' + envType.WINCORE,
                                    value: value,
                                    type: item.type,
                                    gitops_group: item.gitops_group
                                });
                            }
                        } else if ((envItem.split('/').shift() === envType.WINTER) &&
                                    ((filter.toLowerCase() === envType.WINTER) || (item.sub_category.includes(filter.toLowerCase())))) {
                            const suffix = (filter.toLowerCase() === envType.WINTER) ? "-betcloud-" : "-";
                            return ({
                                label: item.label + "-" + filter.toLowerCase(),
                                value: item.label + suffix + filter.toLowerCase()
                            });
                        } else if ((envItem.split('/').shift() === envType.CONSOLIDATED) &&
                                    ((filter === 'ALL') || (filter === item.type))) {
                            return ({
                                label: item.label + "-betcloud-consolidated",
                                value: item.label + "-betcloud-consolidated"
                            });
                        } else if (envItem.split('/').shift() === envType.SREPLAYBOOK) {
                                return ({
                                    label: item.label,
                                    value: item.label.toLowerCase(),
                                    type: item.type
                                });
                        }   else if (envItem === envType.KAFKA) {
                            return ({ label: item.label, value: item.label.toLowerCase() + '-' + envItem });
                        }
                    }
                    return undefined;
                }).filter(Boolean);

                return data
            }).filter(Boolean);

            envData = envData.flat();
            setSimpleData(envData)
            const eTypes = uniq(envData.map(e => { return e.type }).filter(Boolean));
            if  (eTypes.length <= 1) {
                setForceNoCascade(true)
            }

            if ((props.cascade === true) &&
                (forceNoCascade === false)) {
                let cData = []
                const grouped = groupBy(envData, "type")
                keys(grouped).forEach(key => {
                    const nextData = {};
                    nextData["label"] = key;
                    nextData["value"] = key;
                    let keyValue = key
                    if (props.isGitopsStyle === true) {
                        keyValue = mapEnvPickerTypeToGitopsGroup(key)
                    }
                    nextData["children"] = sortBy(get(grouped, key, []).map(({ type, ...rest }) => ({ ...rest, value: `${keyValue}/${rest.value}`})), "label");
                    cData.push(nextData)
                });
                cData = sortBy(cData, "label")
                setCascadeData(cData)
            }
        }
        fetchAuthEnvironments()
        // eslint-disable-next-line
    }, []);

    const renderEnv = () => {
        if (props.showAsTab === true) {
            return (
                <TabPicker
                    name={props.name}
                    value={props.value}
                    data={simpleData}
                    onChange={(value, name) => props.onChange(value, name)}
                />
            );
        } else if ((props.cascade === true) && (forceNoCascade === false)) {
            return (
                <CascadePicker
                    name={props.name}
                    value={props.value}
                    data={cascadeData}
                    groupBy={null}
                    multiPick={props.multiPick}
                    isGitopsStyle={props.isGitopsStyle}
                    rawValue={props.rawValue}
                    type={envClass}
                    width={props.width}
                    onChange={(value, name) => props.onChange(value, name)}
                    onClean={(value, name) => props.onClean(value, name)}
                />
            );
        } else {
            return (
                <SimplePicker
                    name={props.name}
                    value={props.value}
                    data={simpleData}
                    groupBy={(props.type.some(item => item.startsWith(`${envType.CORE}/`)) === true) ? "type" : undefined }
                    multiPick={props.multiPick}
                    type={envClass}
                    width={props.width}
                    onChange={(value, name) => props.onChange(value, name)}
                    onClean={(value, name) => props.onClean(value, name)}
                />
            );
        }
    }

    return (
        <>
            {renderEnv()}
        </>
    );
};

export default EnvPicker;
