import React from "react";
import { Whisper, Popover, IconButton } from "rsuite";
import EditIcon from '@rsuite/icons/Edit';
import Edit from "./Edit";

const Sha = ({...props}) => {
	const editRef = React.useRef();

	const shaElement = <p>{props.value.sha}</p>

    const lastUpdatedAt = (new Date(props.value.last_updated_at)).toLocaleString(undefined, {
                                dateStyle: "full",
                                timeStyle: "long"
                            });

	return (
		<div style={{ display: "flex" }} >
			<Whisper
				enterable
				trigger="hover"
				placement="topEnd"
				speaker={<Popover
					style={{
						margin: "1px",
						padding: "5px",
						borderRadius: "20px",
						border: "5px solid gray",
						backgroundColor: "#f5f4f2"
					}}
				>
					<div style={{ fontStyle: "italic", fontWeight: 'bolder' }}>
						{`Last deployed on ${ lastUpdatedAt }`}
					</div>
				</Popover>
				}
			>
				<div style={{ flex: 8, justifyContent: "left" }}>
					{shaElement}
				</div>
			</Whisper>
			&nbsp;&nbsp;
			<div style={{ flex: 1 }}>
				<Whisper
					trigger="none"
					placement="autoHorizontal"
					ref={editRef}
					speaker={<Popover
								style={{
									margin: "1px",
									padding: "5px",
									borderRadius: "20px",
									border: "5px solid gray",
									backgroundColor: "#f5f4f2" }}
							 >
								<Edit
									group={props.group}
									env={props.env}
									name={props.name}
									value={props.value}
									onClose={() => editRef.current.close()} />
							 </Popover>
							}
				>
					<IconButton
						icon={<EditIcon />}
						circle
						size="xs"
						disabled={props.readOnly}
						onClick={() => editRef.current.open()} />
				</Whisper>
			</div>
		</div>
	);
};

export default Sha;
