import React from "react";
import { Panel, Col, FlexboxGrid } from "rsuite";
import Env from "./env";
import { getGroupColour } from "./utils";

const Group = ({...props}) => {
    const header = <FlexboxGrid justify="start" align="middle">
						<FlexboxGrid.Item colspan={4}>
							<div style={{ borderBottom: "1px solid gray",
										  borderRadius: "16px" }}
							>
								&nbsp;&nbsp;{props.name}
							</div>
						</FlexboxGrid.Item>
					</FlexboxGrid>
    return (
		<>
            {(props.data.length !== 0) &&
                <Panel header={header} shaded style={{ backgroundColor: getGroupColour(props.name), marginBottom: "20px" }} >
                    {(props.data.map((item, index) =>
                        <Col md={6} sm={12} xs={24} key={`col-${index}`} >
                            <Env name={item.env.name} group={props.name} data={item.apps} readOnly={props.readOnly} />
                        </Col>
                    ))}
                </Panel>
            }
		</>
	);
};

export default Group;