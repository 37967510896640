import { projectTypes } from "./app";

export const playbooks = [
        {
            name: 'Add market disclaimer to matches',
            summary: 'Playbook to add market disclaimer to Rugby League matches',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Developers', 'QA-Developers'],
            id: 'update',
            autoplay: [
                {
                    name: 'Add market disclaimer to matches',
                    id: 'add',
                    showEnv: ['wincore/ALL'],
                    apiUrl: 'core/matches/update/disclaimer',
                    updateData: [
                        {
                            label: 'Disclaimer',
                            value: 'disclaimer',
                            type: 'readonly/Settled at \'Normal Time\'.'
                        }
                    ],
                    whereData: [
                        {
                            label: 'Matches',
                            value: 'matches',
                            type: 'readonly/Rugby League'
                        },
                        {
                            label: 'Market',
                            value: 'market',
                            type: 'readonly/Win-Draw-Win'
                        },
                        {
                            label: 'Bet option spectrum id',
                            value: 'bet-option-spectrum-id',
                            type: 'readonly/069.141'
                        }
                    ]
                }
            ]
        },
        {
            name: 'AlloyDB Configuration',
            summary: 'Playbook for alloydb configuration',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform'],
            id: 'insert',
            steps: [
                {
                    name: 'Create bookie database in alloydb',
                    id: 'create',
                    showEnv: ['consolidated/ALL'],
                    apiUrl: 'consolidated/bookie/create',
                    insertData: [
                        {
                            label: 'Bookie name',
                            value: 'bookie_name',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Migrate existing bookie',
                            value: 'migrate_existing_bookie',
                            type: 'boolean',
                            required: true
                        },
                    ]
                },
                {
                    name: 'Run alloydb migration',
                    id: 'run',
                    showEnv: ['consolidated/ALL'],
                    apiUrl: 'consolidated/bookie/migration/run',
                    insertData: [
                        {
                            label: 'Bookie name',
                            value: 'bookie_name',
                            type: 'text',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Create app roles for bookie',
                    id: 'create',
                    showEnv: ['consolidated/ALL'],
                    apiUrl: 'consolidated/bookie/user/create',
                    insertData: [
                        {
                            label: 'Bookie name',
                            value: 'bookie_name',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'App role',
                            value: 'app_roles',
                            type: "apipicker/SELECT DISTINCT name FROM database_app_role where name <> 'betcloud' order by name/name",
                            multiPick: true,
                            required: true
                        },
                        {
                            label: 'Migrate existing bookie',
                            value: 'migrate_existing_bookie',
                            type: 'boolean',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Migrate existing bookie to alloydb',
                    id: 'migrate',
                    showEnv: ['consolidated/ALL'],
                    apiUrl: 'consolidated/bookie/migrate',
                    insertData: [
                        {
                            label: 'Bookie name',
                            value: 'bookie_name',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'App role',
                            value: 'app_roles',
                            type: 'apipicker/SELECT DISTINCT name FROM database_app_role order by name/name',
                            multiPick: true,
                            required: true
                        }
                    ]
                },
                {
                    name: 'Revert alloydb migration of existing bookie',
                    id: 'create',
                    showEnv: ['consolidated/ALL'],
                    apiUrl: 'consolidated/bookie/revert/migration',
                    insertData: [
                        {
                            label: 'Bookie name',
                            value: 'bookie_name',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'App role',
                            value: 'app_roles',
                            type: 'apipicker/SELECT DISTINCT name FROM database_app_role order by name/name',
                            multiPick: true,
                            required: true
                        }
                    ]
                }
            ]
        },
        {
            name: 'Oddsmatrix',
            summary: 'Playbook to resub OM and backfill OM events, competition, maches, markets and sport.',
            // eslint-disable-next-line
            help: 'Follow https://betcloud.atlassian.net/wiki/spaces/BP/pages/382894125/New+resub+process for more details',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Developers', 'QA-Developers'],
            id: 'backfill',
            steps: [
                {
                    name: 'Resub Oddsmatrix',
                    id: 'resub',
                    showEnv: ['winter/oddsmatrix'],
                    apiUrl: 'winter/oddsmatrix/resub'
                },
                {
                    name: 'Backfill Event(s)',
                    id: 'backfill',
                    showEnv: ['winter/oddsmatrix'],
                    apiUrl: 'winter/oddsmatrix/backfill/events',
                    insertData: [
                        {
                            label: 'ID',
                            value: 'event_id',
                            type: 'text',
                            default: 'ALL',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Backfill Competitions',
                    id: 'backfill',
                    showEnv: ['winter/oddsmatrix'],
                    apiUrl: 'winter/oddsmatrix/backfill/competitions'
                },
                {
                    name: 'Backfill Matches',
                    id: 'backfill',
                    showEnv: ['winter/oddsmatrix'],
                    apiUrl: 'winter/oddsmatrix/backfill/matches'
                },
                {
                    name: 'Backfill Sports',
                    id: 'backfill',
                    showEnv: ['winter/oddsmatrix'],
                    apiUrl: 'winter/oddsmatrix/backfill/sports'
                },
                {
                    name: 'Backfill Markets',
                    id: 'backfill',
                    showEnv: ['winter/oddsmatrix'],
                    apiUrl: 'winter/oddsmatrix/backfill/markets',
                    searchByData: [
                        {
                            label: 'Match ID',
                            value: 'match_id',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Sport ID',
                            value: 'sport_id',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Market ID',
                            value: 'market_id',
                            type: 'text',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Backfill Open/Active outcomes by sport',
                    id: 'backfill',
                    showEnv: ['winter/oddsmatrix'],
                    apiUrl: 'winter/oddsmatrix/backfill/adhoc/sports/outcome',
                    insertData: [
                        {
                            label: 'Sport Id',
                            value: 'sport_id',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Type Id',
                            value: 'type_id',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Start Time',
                            value: 'start_time',
                            type: 'timestamp',
                            required: true
                        }
                    ]
                }
            ]
        },
        {
            name: 'Sportcast',
            summary: 'Playbook to fech sportcast raw data and backfill SC tables. For SC backfill IDs are non-mandatory. If provided only that id will be backfilled else entire table.',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Developers'],
            id: 'sportcast',
            steps: [
                {
                    name: 'Fetch sportcast raw data',
                    id: 'search',
                    showEnv: 'none',
                    apiUrl: 'winter/sportcast/sportcast_data/search',
                    searchByData: [
                        {
                            label: 'Fixture Id',
                            value: 'fixture_id',
                            type: 'text',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Backfill Sportscast Competitions',
                    id: 'backfill',
                    showEnv: ['winter/sportcast'],
                    apiUrl: 'winter/sportcast/backfill/competitions',
                    insertData: [
                        {
                            label: 'Competition Id',
                            value: 'event_id',
                            type: 'text',
                            default: 'ALL',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Backfill Sportscast Matches',
                    id: 'backfill',
                    showEnv: ['winter/sportcast'],
                    apiUrl: 'winter/sportcast/backfill/matches',
                    insertData: [
                        {
                            label: 'Match Id',
                            value: 'event_id',
                            type: 'text',
                            default: 'ALL',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Backfill Sportscast Market Groups',
                    id: 'backfill',
                    showEnv: ['winter/sportcast'],
                    apiUrl: 'winter/sportcast/backfill/market_groups',
                    insertData: [
                        {
                            label: 'Match Id',
                            value: 'event_id',
                            type: 'text',
                            default: 'ALL',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Backfill Sportscast Markets',
                    id: 'backfill',
                    showEnv: ['winter/sportcast'],
                    apiUrl: 'winter/sportcast/backfill/markets',
                    insertData: [
                        {
                            label: 'Market Id',
                            value: 'event_id',
                            type: 'text',
                            default: 'ALL',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Backfill Sportscast Propositions',
                    id: 'backfill',
                    showEnv: ['winter/sportcast'],
                    apiUrl: 'winter/sportcast/backfill/propositions',
                    insertData: [
                        {
                            label: 'Proposition Id',
                            value: 'event_id',
                            type: 'text',
                            default: 'ALL',
                            required: true
                        }
                    ]
                }
            ]
        },
        {
            name: 'Backfill DWH Tables',
            summary: 'Playbook to backfill DWH Tables',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE, projectTypes.CASINO],
            roles: ['Platform', 'DWH'],
            id: 'insert',
            autoplay: [
                {
                    name: 'Backfill DWH tables',
                    id: 'backfill',
                    postEnv: true,
                    showEnv: ['core/ALL', 'wincore/ALL'],
                    excludedEnv: ['bat-betcloud', 'perf-betcloud', 'staging-betcloud', 'hotfix-betcloud', 'staging-winter'],
                    apiUrl: 'core/dwh/backfill',
                    insertData: [
                        {
                            label: 'DB Query',
                            value: 'dbquery',
                            type: 'textarea',
                            required: false
                        }
                    ]
                }
            ]
        },
        {
            name: 'Manage punter blacklist',
            summary: 'Playbooks to manage punter blacklist',
            category: [projectTypes.CORE],
            roles: ['Platform', 'CS', 'CS-RO'],
            id: 'insert',
            steps: [
                {
                    name: 'Blacklist a Punter',
                    // eslint-disable-next-line
                    help: 'This playbook is used to blacklist a punter',
                    roles: ['Platform', 'CS'],
                    id: 'blacklist',
                    showEnv: ['core/ALL'],
                    apiUrl: 'core/punter/blacklist',
                    insertData: [
                        {
                            label: 'First Name',
                            value: 'first_name',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Last Name',
                            value: 'last_name',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Date of Birth',
                            value: 'date_of_birth',
                            type: 'birthdate',
                            required: false
                        },
                        {
                            label: 'Post Code',
                            value: 'post_code',
                            type: 'text',
                            required: false
                        },
                        {
                            label: 'Mobile',
                            value: 'mobile',
                            type: 'mobile',
                            required: false
                        },
                        {
                            label: 'Email',
                            value: 'email',
                            type: 'email',
                            required: false
                        }
                    ]
                },
                {
                    name: 'Search Blacklisted Punter',
                    // eslint-disable-next-line
                    help: 'This playbook implements a search with logic identical to the punter \
                            signup logic so by inputting various search parameters and their combinations \
                            you are able to see which record in the black is preventing (or not preventing if none found) \
                            a punter from registering.',
                    roles: ['Platform', 'CS', 'CS-RO'],
                    id: 'search',
                    showEnv: ['core/ALL'],
                    apiUrl: 'core/punter/blacklist/search',
                    insertData: [
                        {
                            label: 'First Name',
                            value: 'first_name',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Last Name',
                            value: 'last_name',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Date of Birth',
                            value: 'date_of_birth',
                            type: 'birthdate',
                            required: false
                        },
                        {
                            label: 'Mobile',
                            value: 'mobile',
                            type: 'mobile',
                            required: false
                        },
                        {
                            label: 'Email',
                            value: 'email',
                            type: 'email',
                            required: false
                        }
                    ]
                },
                {
                    name: 'Revoke Blacklisted Punter',
                    // eslint-disable-next-line
                    help: 'This playbook is used to revoke a blacklisted punter',
                    roles: ['Platform'],
                    id: 'revoke',
                    showEnv: ['core/ALL'],
                    apiUrl: 'core/punter/blacklist/revoke',
                    insertData: [
                        {
                            label: 'First Name',
                            value: 'first_name',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Last Name',
                            value: 'last_name',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Date of Birth',
                            value: 'date_of_birth',
                            type: 'birthdate',
                            required: false
                        },
                        {
                            label: 'Mobile',
                            value: 'mobile',
                            type: 'mobile',
                            required: false
                        },
                        {
                            label: 'Email',
                            value: 'email',
                            type: 'email',
                            required: false
                        }
                    ]
                }
            ]
        },
        {
            name: 'Bulk Update Punter Flags',
            summary: 'Playbook to bulk update punter flags',
            // eslint-disable-next-line
            help: 'Prerequisite: User should have a valid bookie account. Format of the csv is bookie_platform,punter_id,punter_flag,colour,remove_existing_flag \
            eg: betgalaxy,d197a955-a58a-4762-a9cc-edb1497260d5,apple_pay_config,,Y .\
            bookie_platform,punter_id and punter_flag are mandatory fields. remove_existing_flag by default is N.',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Finance'],
            id: 'insert',
            autoplay: [
                {
                    name: 'Bulk Update Punter Flags',
                    id: 'insert',
                    showEnv: 'none',
                    apiUrl: 'core/punter/config/flags/bulk/update',
                    insertData: [
                        {
                            label: 'Punter Flags File',
                            value: 'punter_csv',
                            type: 'uploadfile/text/csv',
                            required: true
                        }
                    ]
                }
            ]
        },
        {
            name: 'Close Open Entities',
            summary: 'Playbook to close open entities',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Developers', 'QA-Developers'],
            id: 'update',
            steps: [
                {
                    name: 'Close Open Matches',
                    id: 'close',
                    showEnv: ['core/DEV', 'core/QAT', 'wincore/DEV', 'wincore/QAT'],
                    apiUrl: 'core/match/update/status',
                    updateData: [
                        {
                            label: 'Status',
                            value: 'status',
                            type: 'readonly/Closed'
                        }
                    ],
                    whereData: [
                        {
                            label: 'Close all matches till',
                            value: 'start_time',
                            type: 'previoustimestamp'
                        }
                    ]
                },
                {
                    name: 'Close Open Races',
                    id: 'close',
                    showEnv: ['core/DEV', 'core/QAT', 'wincore/DEV', 'wincore/QAT'],
                    apiUrl: 'core/race/update/status',
                    updateData: [
                        {
                            label: 'Status',
                            value: 'status',
                            type: 'readonly/Closed'
                        }
                    ],
                    whereData: [
                        {
                            label: 'Close all races till',
                            value: 'start_time',
                            type: 'previoustimestamp'
                        }
                    ]
                }
            ]
        },
        {
            name: 'Bookie Management',
            summary: 'Playbook to manage a bookie',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE, projectTypes.CASINO],
            roles: ['Platform'],
            id: 'insert',
            steps: [
                {
                    name: 'Create a Bookie',
                    id: 'create',
                    showEnv: ['core/ALL', 'wincore/ALL'],
                    apiUrl: 'core/bookie/create',
                    insertData: [
                        {
                            label: 'Email',
                            value: 'email',
                            type: 'email',
                            required: true
                        },
                        {
                            label: 'First Name',
                            value: 'first_name',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Last Name',
                            value: 'last_name',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Mobile',
                            value: 'mobile',
                            type: 'mobile',
                            required: true
                        },
                        {
                            label: 'License ID',
                            value: 'license_id',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'License State',
                            value: 'licensed_state',
                            type: 'statepicker',
                            required: true
                        },
                        {
                            label: 'Admin',
                            value: 'is_admin',
                            type: 'boolean',
                            required: true
                        },
                        {
                            label: 'Roles',
                            value: 'roles',
                            type: 'querypicker/SELECT DISTINCT unnest(roles) as role FROM bookie_permission/role',
                            multiPick: true,
                            required: false
                        }
                    ]
                },
                {
                    name: 'Delete Bookie From Firebase Project',
                    id: 'delete',
                    showEnv: ['core/ALL', 'wincore/ALL'],
                    apiUrl: 'core/bookie/delete',
                    insertData: [
                        {
                            label: 'Email',
                            value: 'email',
                            type: 'email',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Bulk Bookie Creation',
                    id: 'create',
                    showEnv: 'feeder',
                    apiUrl: 'core/bookie/bulk/create',
                    insertData: [
                        {
                            label: 'Bookie Data',
                            value: 'bookie_create_json',
                            type: 'feedstep/Create Bookie Data',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Update Bookie Role',
                    id: 'update',
                    showEnv: ['core/ALL', 'wincore/ALL'],
                    apiUrl: 'core/bookie/update/role',
                    updateData: [
                        {
                            label: 'Roles',
                            value: 'roles',
                            type: 'querypicker/SELECT DISTINCT unnest(roles) as role FROM bookie_permission/role',
                            multiPick: true,
                            required: false
                        }
                    ],
                    whereData: [
                        {
                            label: 'Email',
                            value: 'email',
                            type: 'email'
                        }
                    ]
                }
            ],
            feedsteps: [
                {
                    name: 'Create Bookie Data',
                    id: 'upload',
                    showEnv: 'none',
                    apiUrl: 'core/bookie/convert_csv_to_json',
                    insertData: [
                        {
                            label: 'Bookie CSV sheet',
                            value: 'bookie_csv',
                            type: 'uploadfile/text/csv',
                            required: true
                        }
                    ]
                }
            ]
        },
        {
            name: 'DB helper',
            summary: 'Playbook to fetch data from DB',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE, projectTypes.CASINO],
            roles: ['Platform', 'Developers', 'QA-Developers', 'DB-RO', 'Finance'],
            id: 'search',
            steps: [
                {
                    name: 'Search Card',
                    id: 'search',
                    showEnv: ['core/ALL'],
                    apiUrl: 'core/card',
                    searchByData: [
                        {
                            label: 'Id',
                            value: 'id',
                            type: 'text'
                        },
                        {
                            label: 'Punter Id',
                            value: 'punter_id',
                            type: 'text'
                        }
                    ]
                },
                {
                    name: 'Search Card History',
                    id: 'search',
                    showEnv: ['core/ALL'],
                    apiUrl: 'core/card/history',
                    searchByData: [
                        {
                            label: 'Id',
                            value: 'id',
                            type: 'text'
                        },
                        {
                            label: 'Punter Id',
                            value: 'punter_id',
                            type: 'text'
                        }
                    ]
                },
                {
                    name: 'Search Deposit',
                    id: 'search',
                    showEnv: ['core/ALL'],
                    apiUrl: 'core/deposit',
                    searchByData: [
                        {
                            label: 'Id',
                            value: 'id',
                            type: 'text'
                        },
                        {
                            label: 'Punter Id',
                            value: 'punter_id',
                            type: 'text'
                        }
                    ]
                },
                {
                    name: 'Search Deposit History',
                    id: 'search',
                    showEnv: ['core/ALL'],
                    apiUrl: 'core/deposit/history',
                    searchByData: [
                        {
                            label: 'Id',
                            value: 'id',
                            type: 'text'
                        },
                        {
                            label: 'Punter Id',
                            value: 'punter_id',
                            type: 'text'
                        }
                    ]
                },
                {
                    name: 'Search Punter',
                    id: 'search',
                    showEnv: ['core/ALL'],
                    apiUrl: 'core/punter',
                    searchByData: [
                        {
                            label: 'Punter Id',
                            value: 'id',
                            type: 'text'
                        },
                        {
                            label: 'Email',
                            value: 'email',
                            type: 'email'
                        }
                    ]
                },
                {
                    name: 'Search Withdrawal',
                    id: 'search',
                    showEnv: ['core/ALL'],
                    apiUrl: 'core/withdrawal',
                    searchByData: [
                        {
                            label: 'Id',
                            value: 'id',
                            type: 'text'
                        },
                        {
                            label: 'Punter Id',
                            value: 'punter_id',
                            type: 'text'
                        }
                    ]
                }
            ]
        },
        {
            name: 'Deadlettered Messages',
            summary: 'Playbook to handle deadlettered messages',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Developers', 'QA-Developers'],
            id: 'create',
            steps: [
                {
                    name: 'Fetch deadlettered messages',
                    id: 'fetch',
                    showEnv: ['core/ALL', 'wincore/ALL'],
                    apiUrl: 'core/deadlettered/fetch'
                },
                {
                    name: 'Requeue deadlettered message',
                    id: 'requeue',
                    showEnv: ['core/ALL', 'wincore/ALL'],
                    apiUrl: 'core/deadlettered/requeue',
                    insertData: [
                        {
                            label: 'Message ID',
                            value: 'message_id',
                            type: 'text'
                        }
                    ]
                },
                {
                    name: 'Purge deadlettered message',
                    id: 'purge',
                    showEnv: ['core/ALL', 'wincore/ALL'],
                    apiUrl: 'core/deadlettered/purge',
                    insertData: [
                        {
                            label: 'Message ID',
                            value: 'message_id',
                            type: 'text'
                        }
                    ]
                }
            ]
        },
        {
            name: 'Featured Match Config',
            summary: 'Playbook to configure featured match',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Traders', 'Developers', 'QA-Developers'],
            id: 'featuredmatch',
            autoplay: [
                {
                    name: 'Featured Match Config',
                    id: 'featuredmatch',
                    showEnv: ['core/ALL', 'wincore/ALL'],
                    apiUrl: 'core/quicknav/match'
                }
            ]
        },
        {
            name: 'Featured Race Config',
            summary: 'Playbook to configure featured race',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Traders', 'Developers', 'QA-Developers'],
            id: 'featuredrace',
            autoplay: [
                {
                    name: 'Featured Race Config',
                    id: 'featuredrace',
                    showEnv: ['core/ALL', 'wincore/ALL'],
                    apiUrl: 'core/quicknav/race'
                }
            ]
        },
        {
            name: 'Global Offerings',
            summary: 'Playbook to modify global offerings',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Developers', 'QA-Developers', 'Traders'],
            id: 'globalofferings',
            autoplay: [
                {
                    name: 'Global Offerings',
                    id: 'globalofferings',
                    showEnv: ['core/ALL', 'wincore/ALL'],
                    apiUrl: 'core/offerings'
                }
            ]
        },
        {
            name: 'Insert MBL exception',
            summary: 'Playbook to insert MBL exception in Mongo DB',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform', 'QA-Developers', 'Traders'],
            id: 'insert',
            autoplay: [
                {
                    name: 'Auto Execute Playbook',
                    id: 'insert',
                    showEnv: ['winter'],
                    apiUrl: 'winter/mbl_exception/insert',
                    insertData: [
                        {
                            label: 'MBL file',
                            value: 'mbl_file',
                            type: 'uploadfile/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            required: true
                        }
                    ]
                }
            ]
        },
        {
            name: 'Tote & Exotic Manual Settlement',
            summary: 'Playbook to manually result exotics/totes',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Developers', 'Traders'],
            id: 'upload',
            autoplay: [
                {
                    name: 'Auto Execute Playbook',
                    id: 'Upload',
                    showEnv: ['winter'],
                    disclaimer: "Does not support the ability to re-result (or re-settle) Totes/Exotics. It should only be utilised when no Tote Dividend/s have been received.",
                    apiUrl: 'winter/result/exotic',
                    insertData: [
                        {
                            label: 'External Reference',
                            value: 'external_ref',
                            type: 'externalref',
                            required: true
                        },
                        {
                            label: 'Race Result',
                            value: 'race_result',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Result file',
                            value: 'result_file',
                            type: 'uploadfile/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            required: true
                        }
                    ]
                }
            ]
        },
        {
            name: 'Quick Links Config',
            summary: 'Playbook to configure quick nav',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Traders', 'Developers', 'QA-Developers'],
            id: 'quicknav',
            autoplay: [
                {
                    name: 'Quick Links Config',
                    id: 'quicklinks',
                    showEnv: ['core/ALL', 'wincore/ALL'],
                    apiUrl: 'core/quicknav/links'
                }
            ]
        },
        {
            name: 'Race Data Mixer',
            summary: 'Playbook for race data mixer',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Traders', 'Developers', 'QA-Developers'],
            id: 'races',
            steps: [
                {
                    name: 'Republish Race',
                    id: 'republish',
                    showEnv: ['winter'],
                    apiUrl: 'winter/race-data-mixer/races/republish',
                    roles: ['Platform', 'Traders', 'Developers', 'QA-Developers'],
                    searchByData: [
                        {
                            label: 'External Ref',
                            value: 'external_ref',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Prod wincore Race Id',
                            value: 'race_id',
                            type: 'text',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Get race with markets',
                    id: 'get race',
                    showEnv: ['winter'],
                    apiUrl: 'winter/race-data-mixer/races',
                    roles: ['Platform', 'Developers', 'QA-Developers'],
                    searchByData: [
                        {
                            label: 'External Ref',
                            value: 'external_ref',
                            type: 'text',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Get race with markets mixed',
                    id: 'get race',
                    showEnv: ['winter'],
                    apiUrl: 'winter/race-data-mixer/races/mixed',
                    roles: ['Platform', 'Developers', 'QA-Developers'],
                    searchByData: [
                        {
                            label: 'External Ref',
                            value: 'external_ref',
                            type: 'text',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Sync Venues',
                    id: 'sync',
                    showEnv: ['winter'],
                    apiUrl: 'winter/race-data-mixer/venues/sync',
                    roles: ['Platform', 'Developers', 'QA-Developers']
                },
                {
                    name: 'Get feed status',
                    id: 'Get',
                    showEnv: ['winter'],
                    apiUrl: 'winter/race-data-mixer/config/feed-status',
                    roles: ['Platform', 'Developers', 'QA-Developers'],
                    searchByData: [
                        {
                            label: 'Config ID',
                            value: 'config_id',
                            type: 'text',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Update feed status',
                    id: 'Update',
                    showEnv: ['winter'],
                    apiUrl: 'winter/race-data-mixer/config/feed-status',
                    roles: ['Platform', 'Developers', 'QA-Developers'],
                    updateData: [
                        {
                            label: 'Source',
                            value: 'source',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Enabled',
                            value: 'value',
                            type: 'boolean',
                            required: true
                        }
                    ],
                    whereData: [
                        {
                            label: 'Config ID',
                            value: 'config_id',
                            type: 'text',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Delete feed status',
                    id: 'Delete',
                    showEnv: ['winter'],
                    apiUrl: 'winter/race-data-mixer/config/feed-status/delete',
                    roles: ['Platform', 'Developers', 'QA-Developers'],
                    searchByData: [
                        {
                            label: 'Config ID',
                            value: 'config_id',
                            type: 'text',
                            required: true
                        }
                    ]
                }
            ]
        },
        {
            name: 'Scheduled Price Adjustments',
            summary: 'Playbook to modify scheduled price adjustments',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Developers', 'QA-Developers', 'Traders'],
            id: 'priceadjust',
            autoplay: [
                {
                    name: 'Scheduled Price Adjustments',
                    id: 'priceadjust',
                    showEnv: ['core/ALL', 'wincore/ALL'],
                    apiUrl: 'core/price/adjust'
                }
            ]
        },
        {
            name: 'Manage Default MBL Thresholds',
            summary: 'Playbook to manage default MBL thresholds',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Developers', 'QA-Developers', 'Traders'],
            id: 'mblthreshold',
            autoplay: [
                {
                    name: 'Manage Default MBL Thresholds',
                    id: 'mblthreshold',
                    showEnv: ['core/ALL', 'wincore/ALL'],
                    apiUrl: 'core/mblthreshold'
                }
            ]
        },
        {
            name: 'Time-based Access to PostgreSQL DB',
            summary: 'Playbook to provide time-based access to PostgreSQL DB',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE, projectTypes.CASINO],
            roles: ['Platform'],
            id: 'insert',
            steps: [
                {
                    name: 'Grant Time-based Access to PostgreSQL DB',
                    id: 'grant',
                    showEnv: ['core/ALL', 'wincore/ALL'],
                    apiUrl: 'core/dbaccess/grant',
                    insertData: [
                        {
                            label: 'Email',
                            value: 'email',
                            type: 'email',
                            required: true
                        },
                        {
                            label: 'Access Type',
                            value: 'access_type',
                            type: 'accesstypepicker',
                            required: true
                        },
                        {
                            label: 'Valid Until',
                            value: 'valid_until',
                            type: 'expirytimestamp',
                            required: true
                        },
                        {
                            label: 'Remarks',
                            value: 'remarks',
                            type: 'text',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Revoke Time-based Access to PostgreSQL DB',
                    id: 'revoke',
                    showEnv: ['core/ALL', 'wincore/ALL'],
                    apiUrl: 'core/dbaccess/revoke',
                    insertData: [
                        {
                            label: 'Email',
                            value: 'email',
                            type: 'email',
                            required: true
                        },
                        {
                            label: 'Role Name',
                            value: 'role_name',
                            type: 'text',
                            required: true
                        }
                    ]
                }
            ]
        },
        {
            name: 'Update Match Start Time',
            summary: 'Playbook to update match start time',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Developers', 'QA-Developers', 'Traders'],
            id: 'update',
            autoplay: [
                {
                    name: 'Update Match Start Time',
                    id: 'update',
                    showEnv: ['wincore/ALL'],
                    apiUrl: 'core/match/update/start_time',
                    updateData: [
                        {
                            label: 'Start time',
                            value: 'start_time',
                            type: 'expirytimestamp',
                            required: true
                        }
                    ],
                    whereData: [
                        {
                            label: 'Match Id',
                            value: 'match_id',
                            type: 'text',
                            required: true
                        }
                    ]
                }
            ]
        },
        {
            name: "Update Withdrawal Request",
            summary: "Playbook to update withdrawal request",
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ["Platform", "Finance"],
            id: "update",
            autoplay: [
                {
                    name: "Update Withdrawal Request",
                    id: "update",
                    showEnv: ["core/ALL"],
                    apiUrl: "core/withdrawal/update",
                    updateData: [
                        {
                            label: "Withdrawal Status",
                            value: "withdrawal_status",
                            type: "withdrawalstatuspicker",
                            required: true
                        },
                ],
                whereData: [
                        {
                            label: "Withdrawal Id",
                            value: "id",
                            type: "text",
                            required: true
                        },
                    ],
                },
            ],
        },
        {
            name: 'Punter Management',
            summary: 'Playbook to manage a punter',
            category: [projectTypes.CORE],
            roles: ['Platform', 'CS', 'CS-RO', 'Traders'],
            id: 'insert',
            steps: [
                {
                    name: 'Search Punter Bets',
                    id: 'search',
                    showEnv: ['core/ALL'],
                    // eslint-disable-next-line
                    help: 'This playbook is for searching punter bets for a given punterID.',
                    apiUrl: 'core/punter/search/bets',
                    roles: ['Platform', 'CS', 'CS-RO', 'Traders'],
                    insertData: [
                        {
                            label: 'Punter ID',
                            value: 'punter_id',
                            type: 'text',
                            required: true
                        },
                        {
                            label: 'Sport Name',
                            value: 'sport_name',
                            type: 'querypicker/select distinct display_name from sport order by display_name/display_name',
                            multiPick: false,
                            required: true
                        },
                        {
                            label: 'Date Range',
                            value: 'created_at',
                            type: 'daterange',
                            required: true
                        }
                    ]
                },
                {
                    name: 'Search Punter IP Address',
                    id: 'search',
                    showEnv: ['core/ALL'],
                    // eslint-disable-next-line
                    help: 'This playbook is for searching Punter IP Address for a given punterID.',
                    apiUrl: 'core/punter/search/ipaddress',
                    roles: ['Platform', 'CS', 'CS-RO'],
                    insertData: [
                        {
                            label: 'Punter ID',
                            value: 'punter_id',
                            type: 'text',
                            required: true
                        }
                    ]
                }
            ]
        },
        {
            name: 'Upload Signature Image',
            summary: 'Playbook to upload signature image',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE],
            roles: ['Platform', 'Designers'],
            id: 'insert',
            autoplay: [
                {
                    name: 'Upload Signature Image',
                    id: 'upload',
                    showEnv: 'none',
                    apiUrl: 'generic/signature_image/upload',
                    insertData: [
                        {
                            label: 'Signature Image',
                            value: 'signature_image',
                            type: 'uploadfile/image/png',
                            required: true
                        }
                    ]
                }
            ]
        },
        {
            name: 'Manage API Users',
            summary: 'Playbook to manage API users',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE, projectTypes.CASINO],
            roles: ['Admin'],
            id: 'apicfg/user',
            autoplay: [
                {
                    name: 'Manage API Users',
                    id: 'apicfg/user',
                    showEnv: 'none',
                    apiUrl: 'playbook'
                }
            ]
        },
        {
            name: 'Manage API Environment Categories',
            summary: 'Playbook to manage API environment categories',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE, projectTypes.CASINO],
            roles: ['Admin'],
            id: 'apicfg/envcategory',
            autoplay: [
                {
                    name: 'Manage API Environment Categories',
                    id: 'apicfg/envcategory',
                    showEnv: 'none',
                    apiUrl: 'playbook'
                }
            ]
        },
        {
            name: 'Manage API Environment Sub Categories',
            summary: 'Playbook to manage API environment sub categories',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE, projectTypes.CASINO],
            roles: ['Admin'],
            id: 'apicfg/envsubcategory',
            autoplay: [
                {
                    name: 'Manage API Environment Sub Categories',
                    id: 'apicfg/envsubcategory',
                    showEnv: 'none',
                    apiUrl: 'playbook'
                }
            ]
        },
        {
            name: 'Manage API Environment Types',
            summary: 'Playbook to manage API environment types',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE, projectTypes.CASINO],
            roles: ['Admin'],
            id: 'apicfg/envtype',
            autoplay: [
                {
                    name: 'Manage API Environment Types',
                    id: 'apicfg/envtype',
                    showEnv: 'none',
                    apiUrl: 'playbook'
                }
            ]
        },
        {
            name: 'Manage API Core Environments',
            summary: 'Playbook to manage API core environments',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE, projectTypes.CASINO],
            roles: ['Admin'],
            id: 'apicfg/envcore',
            autoplay: [
                {
                    name: 'Manage API Core Environments',
                    id: 'apicfg/envcore',
                    showEnv: 'none',
                    apiUrl: 'playbook'
                }
            ]
        },
        {
            name: 'Manage API Roles',
            summary: 'Playbook to manage API roles',
            // eslint-disable-next-line
            help: '',
            category: [projectTypes.CORE, projectTypes.CASINO],
            roles: ['Admin'],
            id: 'apicfg/role',
            autoplay: [
                {
                    name: 'Manage API Roles',
                    id: 'apicfg/role',
                    showEnv: 'none',
                    apiUrl: 'playbook'
                }
            ]
        }
    ]
