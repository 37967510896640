import { commonCfg } from "../Common/config";

export const apiCfg = {
    user: {
        KEYS: [
            {
                name: "email",
                width: 6
            },
            {
                name: "roles",
                width: 10
            }
        ],
        ACTIONWIDTH: 8,
        OPERATIONS: [
            commonCfg.OPERATIONS.CREATE,
            commonCfg.OPERATIONS.UPDATE,
            commonCfg.OPERATIONS.DELETE
        ]
    },
    role: {
        KEYS: [
            {
                name: "name",
                width: 6
            },
            {
                name: "remarks",
                width: 10
            }
        ],
        ACTIONWIDTH: 8,
        OPERATIONS: [
            commonCfg.OPERATIONS.CREATE,
            commonCfg.OPERATIONS.UPDATE,
            commonCfg.OPERATIONS.DELETE
        ]
    },
    envcategory: {
        KEYS: [
            {
                name: "category",
                width: 6
            },
            {
                name: "remarks",
                width: 12
            }
        ],
        ACTIONWIDTH: 6,
        OPERATIONS: [
            commonCfg.OPERATIONS.CREATE,
            commonCfg.OPERATIONS.UPDATE,
            commonCfg.OPERATIONS.DELETE
        ]
    },
    envcore: {
        KEYS: [
            {
                name: "type",
                width: 2
            },
            {
                name: "name",
                width: 6
            },
            {
                name: "gitops_group",
                width: 4
            },
            {
                name: "url",
                width: 6
            },
            {
                name: "db_port",
                width: 2
            }
        ],
        ACTIONWIDTH: 4,
        OPERATIONS: [
            commonCfg.OPERATIONS.DELETE
        ]
    },
    envsubcategory: {
        KEYS: [
            {
                name: "category",
                width: 3
            },
            {
                name: "sub_category",
                width: 6
            },
            {
                name: "remarks",
                width: 10
            }
        ],
        ACTIONWIDTH: 5,
        OPERATIONS: [
            commonCfg.OPERATIONS.DELETE
        ]
    },
    envtype: {
        KEYS: [
            {
                name: "type",
                width: 6
            },
            {
                name: "remarks",
                width: 12
            }
        ],
        ACTIONWIDTH: 6,
        OPERATIONS: [
            commonCfg.OPERATIONS.CREATE,
            commonCfg.OPERATIONS.UPDATE,
            commonCfg.OPERATIONS.DELETE
        ]
    }
}